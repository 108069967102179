<template>
  <div>
    <!--begin::Documents-->
    <div class="card card-custom gutter-b">
      <div class="card-header pt-3 pb-3">
        <div class="card-title">
          <h3 class="card-label">Employee List</h3>
        </div>
        <div class="card-toolbar" v-if="user_permissions.includes('se_add_employees')">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span
                  class="v-btn__content"
                  @click="redirectToCreateEmployee()"
                >
                  Add Employee
                </span>
              </button> -->
              <v-row justify="center">
                <a
                  href="#"
                  @click="redirectToCreateEmployee()"
                  class="btn btn-primary font-weight-bolder mr-5"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24"></polygon>
                        <path
                          d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,
                            10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                        <path
                          d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                  Add Employee
                </a>
                <v-dialog v-model="dialog" persistent max-width="600px">
                  <template v-slot:activator="{ on }">
                    <button
                      type="button"
                      class="btn btn-light-primary font-weight-bolder"
                      style="height: 40px;"
                      dark
                      v-on="on"
                    >
                      <span class="v-btn__content">
                        <i
                          style="/! color: #fff; /margin-right: 5px;font-weight: bold;"
                          class="flaticon2-file-1"
                        >
                        </i>
                        Import
                      </span>
                    </button>
                  </template>
                  <!-- IMPORT POPUP CARD -->
                  <v-card>
                    <v-card-title>
                      <span class="headline">Import Employees</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-file-input
                              show-size
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              label="Employee list file"
                              placeholder="Select your files"
                              ref="file"
                              :rules="fileRules"
                              outlined
                              dense
                              v-model="employee_file"
                            >
                            </v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                      <!-- <small>*indicates required field</small> -->
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="blue darken-1" text @click="downloadSample">
                        Download Sample
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="disable_import"
                        @click="importEmployee()"
                      >
                      <b-spinner v-if="disable_import" label="Spinning"></b-spinner>
                        Import
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                  <!-- IMPORT POPUP CARD -->
                </v-dialog>
              </v-row>
              <!-- <button
                type="button"
                class="v-btn v-btn--contained theme--dark v-size--default primary"
              >
                <span class="v-btn__content" @click="importEmployee()">
                  Import Employee
                </span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_employee_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-card>
              <v-card-title class="pt-0 pr-0">
                <v-spacer></v-spacer>
                <div class="col-md-4 my-2 my-md-0 pr-0">
                  <div class="input-icon">
                    <v-text-field
                      v-model="search"
                      single-line
                      placeholder="Search"
                      class="form-control"
                      hide-details
                    ></v-text-field>
                    <span>
                      <i class="flaticon2-search-1 text-muted"></i>
                    </span>
                  </div>
                </div>
              </v-card-title>
              <v-data-table
                v-if="company_type == 'Chapter Organization'"
                :headers="headers_chapter"
                :items="employee_list"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.first_name }} &nbsp; {{ row.item.last_name }}
                    </td>
                    <td>{{ row.item.email }}</td>
                    <td>{{ row.item.role ? row.item.role : "Employee" }}</td>
                    <td v-if="company_type == 'Chapter Organization'">
                      {{ row.item.status == 0 ? "InActive" : "Active" }}
                    </td>
                    <td>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="employeeEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Employee</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="employeeDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Employee</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table v-else :headers="headers" :items="employee_list">
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.first_name }} &nbsp; {{ row.item.last_name }}
                    </td>
                    <td>{{ row.item.email }}</td>
                    <td>{{ row.item.role ? row.item.role : "Employee" }}</td>
                    <td v-if="company_type == 'Chapter Organization'">
                      {{ row.item.status == 0 ? "InActive" : "Active" }}
                    </td>
                    <td>
                      <v-tooltip top v-if="user_permissions.includes('se_add_employees')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="employeeEdit(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Employee</span>
                      </v-tooltip>
                      <v-tooltip top v-if="user_permissions.includes('se_add_employees')">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mx-2"
                            fab
                            small
                            v-on="on"
                            @click="employeeDelete(row.item.id)"
                          >
                            <v-icon color="#B5B5C3">mdi-delete-forever</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Employee</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </form>
      </div>
    </div>
    <!--end::Documents-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_COMPANY } from "@/core/services/store/user.module";
import { DELETE_EMPLOYEE } from "@/core/services/store/employee.module";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "employeesList",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      user_permissions: [],
      employee_list: [],
      search: "",
      dialog: false,
      employee_file: {},
      disable_import: false,
      fileRules: [
        value =>
          !value ||
          value.size < 5000000 ||
          "File size should be less than 5 MB!"
      ],
      company_type: "Small Enterprise",
      headers: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Email", value: "doc_type", width: "25%" },
        { text: "Department", value: "role", width: "15%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ],
      headers_chapter: [
        { text: "Name", align: "left", value: "name", width: "25%" },
        { text: "Email", value: "doc_type", width: "25%" },
        { text: "Department", value: "role", width: "15%" },
        { text: "Status", value: "status", width: "15%" },
        { text: "Action", sortable: false, value: "action", width: "20%" }
      ]
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["getCompany","getUserPermissions"]),
    ...mapGetters(["stateDocumentsList", "stateDocumentObj"]),
    config() {
      return this.layoutConfig();
    }
  },
  mounted() {
    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Employees" }]);
    this.getEmployeesList();
    if (this.getCompany && this.getCompany.length > 0) {
      this.company_type = this.getCompany.company_type;
    } else {
      this.$store.dispatch(GET_COMPANY).then(() => {
        this.company_type = this.getCompany.company_type;
      });
    }
  },
  watch: {
    search: function(newVal) {
      this.getEmployeesList(newVal);
    },
    getCompany: function(newVal) {
      this.company_type = newVal.company_type
        ? newVal.company_type
        : "Small Enterprise";
    },
    getUserPermissions: function(newVal) {
      this.user_permissions = newVal;
    }
  },
  methods: {
    downloadSample() {
      window.open("/media/sample/SampleEmployeeImport.xlsx");
    },
    employeeDelete(emp_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Employee!",
        showCancelButton: true,
        confirmButtonText: "Delete"
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$store.dispatch(DELETE_EMPLOYEE, emp_id).then(() => {
            this.getEmployeesList();
          });
        } else if (result.isDismissed) {
          Swal.fire("Employee is safe.", "", "info");
        }
      });
    },
    redirectToCreateEmployee() {
      this.$router.push({ name: "employeesCreate" });
    },
    getEmployeesList(search_str = "") {
      let context = this;
      let api_url = "employee";
      if (search_str) {
        api_url = "employee?search_str=" + search_str;
      }
      axios({
        method: "get",
        url: api_url,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.employee_list = result.data.employees;
        },
        function() {
          Swal.fire("Error", "Error in fetching employees list.", "info");
        }
      );
    },
    employeeEdit(emp_id) {
      this.$router.push({ name: "employees.edit", params: { id: emp_id } });
      // this.$store.dispatch(GET_DOCUMENT_BY_ID, doc_id).then(() => {
      //   this.$router.push({ name: "documents.edit", params: { id: doc_id } });
      // });
      // Swal.fire("Upcomming feature.", "", "info");
    },
    importEmployee() {
      let context = this;
      context.disable_import = true;
      var formData = new FormData();
      formData.append("sample_file", context.employee_file);
      formData.append(
        "is_chapter_user",
        this.company_type == "Small Enterprise" ? 0 : 1
      );
      axios
        .post("import-employees", formData)
        .then(result => {
          if (result.data.error) {
            Swal.fire("Error", result.data.error[0], "error");
          } else {
            Swal.fire("Saved", "Employee list import successfully", "success");
          }
        })
        .then(() => {
          context.getEmployeesList();
          context.employee_file = "";
          context.disable_import = false;
          context.dialog = false;
        });
    }
  }
};
</script>
